import { CollectionListResponse } from "authory-api-types/dist/types";
import {
    UserCollection,
    UserCollections,
    UserCollectionShareOff,
    UserCollectionShareOn,
    UserCreateCollection,
    UserDeleteCollection,
    UserUpdateCollection
} from "authory-api-types/dist/endpoints";
import { Collection } from "authory-api-types/dist/types/collections";
import axios from "axios";
import { API_URL } from ".";
import { Token } from "../types/queries";
import { getHeaders } from "./get-headers";
import { UserCollectionGetRules, UserCollectionPinArticle, UserCollectionSetRules, UserSetCollectionItemOrder } from "authory-api-types/dist/endpoints";
import { CollectionRuleListResponse } from "authory-api-types/dist/types/response";
import { SetCollectionRulesBodyParams, CreateCollectionBodyParams, CollectionFilterQueryParams, UpdateCollectionBodyParams, PinArticleToCollectionBodyParams, SetCollectionItemOrderBodyParams, UpdateCollectionShareBodyParams } from "authory-api-types/dist/types/request";

export interface CommonArgs {
    userSlug: string,
    token: string,
}

interface CreateCollectionArgs extends CommonArgs {
    data: CreateCollectionBodyParams
    skipNavigate?: boolean
    showNotification?: boolean
}

export const createCollection = async ({ token, userSlug, data }: CreateCollectionArgs) => {
    const res = await axios.post<Collection>(
        `${API_URL}${UserCreateCollection.replace(":userId?", userSlug)}`,
        {
            ...data
        },
        getHeaders(token)
    );

    return res.data;
};
interface deleteCollectionArgs extends CommonArgs {
    collectionId: string
}

export const deleteCollection = async ({ token, userSlug, collectionId }: deleteCollectionArgs) => {
    const { data } = await axios.post(
        `${API_URL}${UserDeleteCollection.replace(":userId?", userSlug).replace(":collectionId", encodeURIComponent(collectionId))}`,
        {},
        getHeaders(token)
    );

    return data;
};

interface updateShareArgs extends CommonArgs {
    status: boolean,
    collectionSlug: string,
    showNotification?: boolean,
    params: UpdateCollectionShareBodyParams
}

export const updateShare = async ({ token, userSlug, status, collectionSlug, params }: updateShareArgs) => {

    const url = status
        ? `${API_URL}${UserCollectionShareOn.replace(":userId?", userSlug).replace(":collectionId", collectionSlug)}`
        : `${API_URL}${UserCollectionShareOff.replace(":userId?", userSlug).replace(":collectionId", collectionSlug)}`

    const { data } = await axios.post<{ token: string }>(url, params, getHeaders(token));

    return data;
};

export const getCollection = async (token: string, userId: string, collectionId: string) => {
    const { data } = await axios.get<Collection>(
        `${API_URL}${UserCollection.replace(":userId?", userId).replace(":collectionId", collectionId)}`,
        getHeaders(token)
    );

    return data;
}

export const getCollections = async (
    token: Token,
    userId: string,
    params: CollectionFilterQueryParams
) => {

    if (Array.isArray(params.type)) {
        if (params.type.length === 1) params.type = params.type[0];
        else params.type = undefined;
    }

    const { data } = await axios.get<CollectionListResponse>(
        `${API_URL}${UserCollections.replace(":userId", userId)}`,
        {
            params,
            ...getHeaders(token!)
        }
    );

    return data;
}

interface getV3CollectionRulesArgs extends CommonArgs {
    collectionId: string,
}

export const getV3CollectionRules = async ({ userSlug, token, collectionId }: getV3CollectionRulesArgs) => {
    const { data } = await axios.get<CollectionRuleListResponse>(
        `${API_URL}${UserCollectionGetRules.replace(":userId?", userSlug).replace(":collectionId", collectionId)}`,
        getHeaders(token)
    );

    return data;
}


interface updateV3CollectionRulesProps extends CommonArgs {
    params: SetCollectionRulesBodyParams,
    collectionId: string,
    skipNotification?: boolean,
}

export const updateV3CollectionRules = async ({ token, userSlug, params, collectionId }: updateV3CollectionRulesProps) => {

    const res = await axios.post<SetCollectionRulesBodyParams>(
        `${API_URL}${UserCollectionSetRules.replace(":userId?", userSlug).replace(":collectionId", collectionId)}`,
        params,
        getHeaders(token)
    );

    return res.data;
};


interface V3UpdateCollectionParams extends CommonArgs {
    params: UpdateCollectionBodyParams,
    collectionId: string,
    useOptimistic?: boolean,
    invalidateContent?: boolean,
    target?: string,
}

export const V3UpdateCollection = async ({ token, userSlug, params, collectionId }: V3UpdateCollectionParams) => {

    const res = await axios.post<Collection>(
        `${API_URL}${UserUpdateCollection.replace(":userId?", userSlug).replace(":collectionId", encodeURIComponent(collectionId))}`,
        params,
        getHeaders(token)
    );

    return res.data;
};


interface PinToCollectionsParams extends CommonArgs {
    params: PinArticleToCollectionBodyParams,
    collectionId: string,
}

export const PinArticleToCollection = async ({ token, userSlug, params, collectionId }: PinToCollectionsParams) => {

    const res = await axios.post(
        `${API_URL}${UserCollectionPinArticle.replace(":userId?", userSlug).replace(":collectionId", encodeURIComponent(collectionId))}`,
        params,
        getHeaders(token)
    );

    return res.data;
}

interface setCollectionItemOrderParams extends CommonArgs {
    params: SetCollectionItemOrderBodyParams,
    collectionId: string,
}

export const setCollectionItemOrder = async ({ token, userSlug, params, collectionId }: setCollectionItemOrderParams) => {

    const res = await axios.post(
        `${API_URL}${UserSetCollectionItemOrder.replace(":userId?", userSlug).replace(":collectionId", encodeURIComponent(collectionId))}`,
        params,
        getHeaders(token)
    );

    return res.data;
}